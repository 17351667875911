import React from 'react';
import styled from 'styled-components/macro';
import { breakpoint } from '@rally/rewards-util';
import { Styled } from '@rally/rewards-view/type';
import { BeforeTitleFocus } from '@rally/rewards-view/component';
import { BlueRibbonV2 } from '@rally/rewards-view/color';

const PageTitle: React.FC<Styled<{ text: string }>> = ({ className, text }) => {
  return (
    <TitleContainer className={className}>
      <BeforeTitleFocus />
      <Title dangerouslySetInnerHTML={{ __html: text }} tabIndex={0} title={text} />
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  height: 40%;
  width: 80%;

  @media ${breakpoint.MOBILE} {
    width: 96%;
  }
`;

const Title = styled.h1`
  color: ${BlueRibbonV2};
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 51px;
  font-weight: 600;
  line-height: 58px;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 80%;

  @media ${breakpoint.TABLET} {
    font-size: 5vw;
    width: 100%;
  }

  @media ${breakpoint.MOBILE} {
    font-size: 34px;
    line-height: 38px;
    text-align: center;
    width: 100%;
  }
`;

export default PageTitle;
