import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { AppState } from '@rally/renew-active/store';
import { UHCButtonDarkBackground } from '@rally/rewards-view/color';
import { MobileNav } from '.';

const HiddenMobileNav = () => {
  const showNav = useSelector((state: AppState) => state.page.showNav);

  return (
    <HiddenNav
      aria-hidden={showNav ? 'false' : 'true'}
      className={showNav ? 'show' : 'hidden'}
      tabIndex={showNav ? 0 : -1}
    >
      <MobileNav />
    </HiddenNav>
  );
};

const HiddenNav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;

  &.hidden {
    left: -100vw;
    opacity: 0;
    transition: left 0.3s, opacity 0.3s;
  }

  &.show {
    left: 0.01vw; /* needed for ie11 */
    opacity: 1;
    transition: left 0.3s, opacity 0.3s;
  }

  & > a {
    border-bottom: 1px solid ${UHCButtonDarkBackground} !important;
  }
`;

export default HiddenMobileNav;
