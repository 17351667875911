export const pageTitle = 'Fitness Location Features';

export const activateBody = {
  title: 'Exercise your ability to get a free gym membership at no additional cost.',
  content: [
    {
      title: 'Convenient and close to home.',
      text: 'Get a membership, take classes and work out at any participating location near you.',
    },
    {
      title: 'Access to a large national network of gyms and fitness locations.',
      text: '',
    },
    {
      title: 'Find the fitness location that fits your style.',
      text: 'Choose from popular local and national gyms, fitness locations and community organizations.',
    },
    {
      title: 'You have options.',
      text: 'If you prefer to work out from home, you can view thousands of on-demand workout videos and live streaming fitness classes.',
    },
  ],
};

export const didYouKnow = {
  labelTitle: 'Find a Fitness Location Near You',
  title: 'Did you know?',
  text: 'Choosing a fitness location close to your home is one of the most effective ways to start and keep a consistent workout habit. Renew Active includes a gym membership with over 17,000 gyms and fitness locations — so you’ll likely find plenty of options near you.',
  label: 'Enter ZIP Code',
};

export const stepUp = {
  title: 'Step up to fitness classes for every interest and ability.',
  subtitle: '',
  content: [{ title: '', text: 'Explore a whole range of new experiences. Who knew working out could be this fun?' }],
  superScript: '1',
};
