export const pageTitle = 'Join Our Fitbit<sup>&reg;</sup> Community';

export const getConnectedV2 = {
  title:
    'Access to the online Fitbit® Community for Renew Active, which allows you to connect and engage with other members.',
  content: [
    {
      title: 'For members only.',
      text: 'The online Fitbit Community for Renew Active is a great way to connect with other health-minded members and have fun discussions about health and wellness. No Fitbit device is needed.',
    },
    {
      title: 'Fitness at home.',
      text: "As a member of the Fitbit Community for Renew Active, you'll have access to Fitbit Premium. Take your fitness to the next level with thousands of workout videos – bodyweight, yoga, meditation, and more.",
    },
    {
      title: 'Help with staying motivated.',
      text: 'Our Fitbit Community for Renew Active helps make it easy to share your fitness milestones and participate in fun, real-time challenges. Both can help keep you motivated and progressing toward your goals.',
    },
    {
      title: 'A healthy lifestyle.',
      text: 'With our Fitbit Community for Renew Active and Fitbit Premium, Renew Active members have a wide variety of ways to engage in a healthy lifestyle. Explore and see which activities serve you best.',
    },
  ],
};
