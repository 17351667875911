import React from 'react';
import styled from 'styled-components/macro';
import { breakpoint } from '@rally/rewards-util';
import { UHCLinkV2 } from '@rally/rewards-view/color';
import { Styled } from '@rally/rewards-view/type';
import { PhoneNumber } from '../Components';

const PhoneNumberWithCode = (
  props: Styled<{
    inHeader?: boolean;
    postText?: string;
    preText?: string;
  }>
) => {
  const { inHeader = false, postText, preText = '' } = props;

  const PhoneNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 42px;
    ${!inHeader && 'align-items: flex-end;'}
    padding: 12px 5px 5px 5px;

    @media ${breakpoint.TABLET_OR_SMALLER} {
      align-items: center;
      height: auto;
    }
  `;

  const CallText = styled.p`
    color: ${UHCLinkV2};
    font-size: ${inHeader ? '14px' : '15px'};
    margin: 0 0 ${inHeader ? '2px' : '5px'} 0;
    ${inHeader ? 'line-height: 16px;' : ''};
    padding: 0;
  `;

  return (
    <PhoneNumberContainer>
      <CallText>{preText} Call UnitedHealthcare</CallText>
      <PhoneNumber inHeader={inHeader} />
      {postText && <CallText>{postText}</CallText>}
    </PhoneNumberContainer>
  );
};

export default PhoneNumberWithCode;
