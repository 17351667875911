//@ts-nocheck
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';

export const DatadogScript = React.memo(function DatadogScript() {
  const env = (window as any).RENEW_ACTIVE.RENEW_ACTIVE_DD_ENV;
  const version = (window as any).RENEW_ACTIVE.RENEW_ACTIVE_DD_VERSION;
  const applicationId = (window as any).RENEW_ACTIVE.RENEW_ACTIVE_APPLICATION_ID;
  const clientToken = (window as any).RENEW_ACTIVE.RENEW_ACTIVE_CLIENT_TOKEN;

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'renew-active-prospect',
    env,
    version,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  return null;
});

export default DatadogScript;
