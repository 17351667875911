import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11'; // https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md#supporting-internet-explorer
import 'custom-event-polyfill'; // for adobe analytics, see https://wiki.audaxhealth.com/display/ENG/Adobe+Analytics#AdobeAnalytics-Q:Whyisn'tthisworkinginIE?
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store } from '@rally/renew-active/store';
import { RenewActiveEnvVars } from '@rally/renew-active/constant';
import { Environment } from '@rally/rewards-util';

declare global {
  interface Window {
    RENEW_ACTIVE: RenewActiveEnvVars;
    ENVIRONMENT: Environment;
  }
}

axios.defaults.baseURL = window.location.origin;
axios.defaults.timeout = 10000;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
