import React from 'react';
import styled from 'styled-components/macro';
import { trackLink } from '@rally/rewards-view/analytics';
import { UHCButtonDarkText } from '@rally/rewards-view/color';
import { A } from '@rally/rewards-view/component';
import { Adobe } from '@rally/renew-active/constant';
import LinkOutIcon from '../assets/linkOut.svg';

const LinkOut = (props: {
  className?: string;
  children?: React.ReactNode;
  lang?: string;
  linkName?: string;
  to: string;
  alt?: string;
}) => (
  <A
    className={props.className}
    href={props.to}
    onClick={() => trackLink(props.linkName || Adobe.noTrack)}
    rel="noopener noreferrer"
    target="_blank"
  >
    <LinkOutText lang={props.lang}>
      {props.children}
      <IconContainer>
        <img alt={props.alt || 'link-out'} src={LinkOutIcon} />
      </IconContainer>
    </LinkOutText>
  </A>
);

const LinkOutText = styled.p`
  color: ${UHCButtonDarkText};
  font-size: 15px;
  margin: 10px 0 0 0;
  overflow-wrap: break-word;
`;

const IconContainer = styled.span`
  align-items: end;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  margin: 0 0 0 10px;
  width: 24px;
`;

export default LinkOut;
