import React from 'react';
import styled from 'styled-components/macro';
import { FooterBackground, FooterBorder } from '@rally/rewards-view/color';
import { breakpoint } from '@rally/rewards-util';
import { ProspectDisclaimer  } from '@rally/renew-active/component';
import { Legal } from '../Components/Legal';
import FooterLinks from '../Components/FooterLinks';
import PhoneNumberWithCode from './PhoneNumberWithCode';
import UHCLogoSrcV2 from '../assets/uhcLogoV2.svg';

const Footer = () => {
  return (
    <Container data-testid="prospect-footer">
      <LogoWithPhoneNumber>
        <UHCLogo alt="UnitedHealthcare Logo" src={UHCLogoSrcV2} />
        <PhoneNumberWithCode />
      </LogoWithPhoneNumber>
      <FooterLinksWithMargin />
      <ProspectDisclaimer />
      <Legal/>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${FooterBackground};
  border-top: 6px solid ${FooterBorder};
  padding: 40px 20px;
`;

const LogoWithPhoneNumber = styled.div`
  align-items: center;
  border-bottom: 2px solid ${FooterBorder};
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  padding-bottom: 47px;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    flex-direction: column;
    height: 120px;
  }
`;

const FooterLinksWithMargin = styled(FooterLinks)`
  margin: 29px 0;
`;

const UHCLogo = styled.img`
  min-width: 295px;
  width: 295px;
`;

export default Footer;
