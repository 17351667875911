import React from 'react';
import styled from 'styled-components/macro';
import { Styled } from '@rally/rewards-view/type';

const Shape: React.FC<Styled<{}>> = ({ children, className }) => {
  return (
    <ShapeContainer>
      <ShapePosition className={className}>{children}</ShapePosition>
    </ShapeContainer>
  );
};

const ShapeContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const ShapePosition = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export default Shape;
