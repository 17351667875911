import React from 'react';
import styled from 'styled-components/macro';
import { ContentRow } from '@rally/rewards-view/component';
import { breakpoint } from '@rally/rewards-util';
import { page } from '@rally/renew-active/hoc';
import { SectionInfo } from '@rally/renew-active/component';
import { PageTitle } from './shared';
import { getConnectedV2, pageTitle } from '../constants/fitbitText';
import fitbitHeroV2 from '../assets/fitbit/fitbitHeroV2.jpg';
import fitbitHeroV2Mobile from '../assets/fitbit/fitbitHeroV2Mobile.jpg';
import fitbitPhoneV2 from '../assets/fitbit/fitbitPhoneV2.jpg';

const Fitbit = () => {
  return (
    <Container>
      <TopContentRow>
        <></>
        <FitbitPageTitleV2 text={pageTitle} />
      </TopContentRow>
      <BottomContentRow>
        <SectionInfo {...getConnectedV2} />
        <FitbitsImg alt="" src={fitbitPhoneV2} />
      </BottomContentRow>
    </Container>
  );
};

const FitbitPageTitleV2 = styled(PageTitle)`
  width: 90%;

  h1 {
    margin-top: 24px;
    width: 100%;

    sup {
      font-size: 28px;
    }
  }

  @media ${breakpoint.TABLET} {
    h1 {
      margin-top: -25px;

      sup {
        font-size: 18px;
      }
    }
  }

  @media ${breakpoint.MOBILE} {
    h1 {
      sup {
        font-size: 16px;
      }
    }
  }
`;

const TopContentRow = styled(ContentRow)`
  background: white url(${fitbitHeroV2}) no-repeat top left;
  background-size: 100% auto;
  height: 346px;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: 225px;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${fitbitHeroV2Mobile});
    background-position: bottom center;
    background-size: cover;
    height: 377px;
    margin: 0;
  }
`;

const BottomContentRow = styled(ContentRow)`
  background-color: white;
  padding-top: 20px;
  z-index: 3;

  @media ${breakpoint.TABLET} {
    > div > div > div > div {
      align-items: flex-start;
    }
  }

  @media ${breakpoint.MOBILE} {
    margin: 0 0 20px 0;
  }
`;

const Container = styled.div`
  overflow: hidden;
`;

const FitbitsImg = styled.img`
  max-width: 400px;
  width: 70%;

  @media ${breakpoint.TABLET} {
    margin-top: calc(30px - 5%);
  }

  @media ${breakpoint.MOBILE} {
    margin-top: 5%;
  }
`;

export default page(Fitbit, 'Fitbit');
