import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { breakpoint } from '@rally/rewards-util';
import { UHCLink } from '@rally/rewards-view/color';
import { trackLink } from '@rally/rewards-view/analytics';
import { Adobe } from '@rally/renew-active/constant';
import LinkOut from './LinkOut';
import PagePath from '../constants/PagePath';

const FooterLinks = (props: { className?: string }) => {
  return (
    <LinksContainer className={props.className}>
      <PaddedLinkIn onClick={() => trackLink(Adobe.prospectFooterPrivacyPolicy)} to={PagePath.Privacy}>
        Privacy Policy
      </PaddedLinkIn>
      <PaddedLinkIn onClick={() => trackLink(Adobe.prospectFooterTOS)} to={PagePath.Terms}>
        Terms of Use
      </PaddedLinkIn>
      <BlockLinkOut linkName={Adobe.prospectFooterAccessibility} to="https://www.uhc.com/legal/accessibility">
        Accessibility
      </BlockLinkOut>
      <BlockLinkOut
        linkName={Adobe.prospectFooterLanguageAssistance}
        to="https://www.medicare.uhc.com/content/dam/UCP/Individual/Non-Discrimination_Form_English.pdf"
      >
        Language Assistance / Non-Discrimination Notice (PDF)
      </BlockLinkOut>
      <BlockLinkOut
        lang="es"
        linkName={Adobe.prospectFooterLanguageAssistanceSpanish}
        to="https://www.medicare.uhc.com/content/dam/UCP/Individual/Non-Discrimination_Form_Spanish.pdf"
      >
        Asistencia de Idiomas / Aviso de No Discriminación
      </BlockLinkOut>
      <BlockLinkOut
        lang="zh"
        linkName={Adobe.prospectFooterLanguageAssistanceChinese}
        to="https://www.medicare.uhc.com/content/dam/UCP/Individual/Non-Discrimination_Form_Chinese.pdf"
      >
        語言協助 / 不歧視通知
      </BlockLinkOut>
    </LinksContainer>
  );
};

const BlockLinkOut = (props: { children?: React.ReactNode; lang?: string; linkName?: string; to: string }) => {
  return (
    <div>
      <PaddedInlineLinkOut {...props} alt="opens in a new window">
        {props.children}
      </PaddedInlineLinkOut>
    </div>
  );
};

const BlockLinkStyles = `
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
`;

const PaddedInlineLinkOut = styled(LinkOut)`
  ${BlockLinkStyles}
`;

const PaddedLinkIn = styled(Link)`
  /* stylelint-disable */
  ${BlockLinkStyles}
  color: ${UHCLink};
  cursor: pointer;
  margin-top: 20px;
  overflow-wrap: break-word;
  padding-top: 1px;
  text-decoration: underline;
  /* stylelint-enable */
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    flex-direction: column;
  }
`;

export default FooterLinks;
