import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { breakpoint } from '@rally/rewards-util';
import { useMatchMedia } from '@rally/rewards-view/hook';
import { NavBackground } from '@rally/rewards-view/color';
import { ButtonLink } from '@rally/rewards-view/component';
import { FindAPlanModal, RenewActiveMemberModal } from '@rally/renew-active/component';
import { Adobe } from '@rally/renew-active/constant';
import { findPlanLink } from '@rally/renew-active/util';
import { ModalActions } from '@rally/renew-active/store';
import { DesktopHeader, MobileHeader, PhoneNumberWithCode } from '.';

const Header = () => {
  const isMobile = useMatchMedia(breakpoint.TABLET_OR_SMALLER);
  const dispatch = useDispatch();

  return (
    <>
      <StickyHeader id="header">{isMobile ? <MobileHeader /> : <DesktopHeader />}</StickyHeader>

      <FindAPlanModal>
        <ModalContainer>
          <Contact>
            <PhoneNumberWithCode postText="8 a.m. to 8 p.m., 7 days a week" preText="Questions?" />
          </Contact>
          <Cta>
            <ButtonLink
              data-testid="plan"
              linkName={Adobe.comparePlansOnline}
              onClick={() => dispatch(ModalActions.findAPlanModal(false))}
              target="_blank"
              theme="darkV2"
              to={findPlanLink()}
            >
              Compare Plans
            </ButtonLink>
          </Cta>
        </ModalContainer>
      </FindAPlanModal>
      <RenewActiveMemberModal />
    </>
  );
};

const Item = css`
  align-items: center;
  display: flex;
`;

const StickyHeader = styled.header`
  background-color: ${NavBackground};
  position: relative;
  width: 100%;
  z-index: 100;

  @supports (position: sticky) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
`;

const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 45%;

  @media ${breakpoint.TABLET_OR_SMALLER} {
    flex-direction: column;
    width: 100%;
  }
`;

const Contact = styled.div`
  margin-right: 60px;
  ${Item}

  @media ${breakpoint.TABLET_OR_SMALLER} {
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

const Cta = styled.div`
  ${Item}
`;

export default Header;
