import React from 'react';
import styled from 'styled-components/macro';
import { DesktopNavBorder } from '@rally/rewards-view/color';

const NavBar = (props: { children?: React.ReactNode }) => <Nav>{props.children}</Nav>;

const Nav = styled.nav`
  display: flex;

  > * {
    border-bottom: 1px solid ${DesktopNavBorder};
    flex: 1 1 auto;
    font-weight: 600;
  }
`;

export default NavBar;
