import styled from 'styled-components/macro';
import { breakpoint } from '@rally/rewards-util';

const TriangleContainer = styled.div<{ z?: number }>`
  height: 80px;
  margin: -1px 0; /* safari + ie11 mobile fix */
  position: relative;
  width: 100%;
  z-index: ${(props) => props.z || 1};

  @media ${breakpoint.MOBILE} {
    height: 30px;
  }
`;

export { TriangleContainer };
