import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { AppState, ModalActions } from '@rally/renew-active/store';
import { PageName } from '@rally/renew-active/hoc';
import { Logo, ProspectNavButton } from '@rally/renew-active/component';
import { ButtonLink, ExternalLink } from '@rally/rewards-view/component';
import { UHCLinkV2 } from '@rally/rewards-view/color';
import { Adobe } from '@rally/renew-active/constant';
import { CONTENT_PX } from '@rally/rewards-util';
import { NavBar } from '.';
import PagePath from '../constants/PagePath';
import PhoneNumberWithCode from './PhoneNumberWithCode';

const DesktopHeader: React.FC<RouteComponentProps> = () => {
  const page = useSelector((state: AppState) => state.page.page);
  const isSelected = (s: string): boolean => s === page;
  const ariaCurrent = (p: PageName) => (p === page ? 'page' : undefined);
  const dispatch = useDispatch();
  const dateDisplay = () => {
    return new Date() < new Date('01/05/2024');
  };

  return (
    <MainContainer>
      <Container>
        <LogoContainer>
          <LogoSize>
            <a href="/">
              <Logo />
            </a>
          </LogoSize>
        </LogoContainer>
        <RightSideHeader>
          <RenewActiveMember>
            <RenewActiveMemberLink
              linkName={Adobe.alreadyMember}
              onClick={(evt) => {
                evt.preventDefault();
                dispatch(ModalActions.renewActiveMemberModal(true));
              }}
              to="#"
            >
              Already have Renew Active?
            </RenewActiveMemberLink>
          </RenewActiveMember>
          <Cta>
            <ButtonLink
              data-testid="planModal"
              linkName={Adobe.findPlan}
              onClick={(evt) => {
                evt.preventDefault();
                dispatch(ModalActions.findAPlanModal(true));
              }}
              theme="darkV2"
              to="#"
            >
              Need a Medicare Plan?
            </ButtonLink>
            <div hidden={!dateDisplay()}>
              <PhoneNumberWithCode inHeader={true} />
            </div>
          </Cta>
        </RightSideHeader>
      </Container>
      <NavBar>
        <ProspectNavButton
          aria-current={ariaCurrent('Home')}
          data-testid="home"
          linkName={Adobe.navHome.prospect}
          selected={isSelected('Home')}
          to={PagePath.Home}
        >
          Home
        </ProspectNavButton>
        <ProspectNavButton
          aria-current={ariaCurrent('Benefits')}
          data-testid="benefits"
          linkName={Adobe.navBenefits}
          selected={isSelected('Benefits')}
          to={PagePath.FitnessFeatures}
        >
          Fitness Location Features
        </ProspectNavButton>
        <ProspectNavButton
          aria-current={ariaCurrent('Location')}
          data-testid="location"
          linkName={Adobe.navLocation}
          selected={isSelected('Location')}
          to={PagePath.GymSearch}
        >
          Find a Fitness Location
        </ProspectNavButton>
        <ProspectNavButton
          aria-current={ariaCurrent('Classes')}
          data-testid="classes"
          linkName={Adobe.navClasses}
          selected={isSelected('Classes')}
          to={PagePath.Classes}
        >
          Classes, Clubs &amp; Events Near You
        </ProspectNavButton>
        <ProspectNavButton
          aria-current={ariaCurrent('Brain')}
          data-testid="brain"
          linkName={Adobe.navBrain}
          selected={isSelected('Brain')}
          to={PagePath.Brain}
        >
          Focus on Brain Health
        </ProspectNavButton>
      </NavBar>
    </MainContainer>
  );
};

const AlignItemsCenter = css`
  align-items: center;
  display: flex;
`;

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: ${CONTENT_PX}px;
`;

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const LogoSize = styled.div`
  width: 385px;
`;

const LogoContainer = styled.div`
  flex: 1 1 auto;
  ${AlignItemsCenter}
`;

const RightSideHeader = styled.div`
  align-items: flex-start;
  display: flex;
  padding-top: 28px;
`;

const Cta = styled.div`
  flex-direction: column;
  justify-content: center;
  ${AlignItemsCenter}
`;

const RenewActiveMember = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 44px;
  padding-left: 40px;
`;

const RenewActiveMemberLink = styled(ExternalLink)`
  color: ${UHCLinkV2};
  font-size: 14px;
  font-weight: bold;
  margin-left: auto;
  text-decoration: none;
  width: 116px;
`;

export default withRouter(DesktopHeader);
