import React from 'react';
import styled, { css } from 'styled-components/macro';
import { UHCLinkV2 } from '@rally/rewards-view/color';
import { A } from '@rally/rewards-view/component';
import { trackLink } from '@rally/rewards-view/analytics';
import { Adobe } from '@rally/renew-active/constant';

const PhoneNumber = (props: { inHeader?: boolean }) => {
  const { inHeader = false } = props;

  // Phone number is hard coded as part of US6111296 as we are going to remove the dependency on geoferret service.
  const phoneNumber = '+1 (855) 604-1588';

  const PhoneNumberStyle = css`
    color: ${UHCLinkV2};
    flex: 1 1 auto;
    font-size: ${inHeader ? '15px' : '24px'};
    font-weight: bold;
    line-height: ${inHeader ? '16px' : '29px'};
  `;

  const PhoneNumberLink = styled(A)`
    ${PhoneNumberStyle}
  `;

  const PhoneNumberExt = styled.span`
    ${PhoneNumberStyle}
  `;

  return (
    <div>
      <PhoneNumberLink
        data-testid="phone-number"
        href={`tel:+${phoneNumber}`}
        onClick={() => trackLink(Adobe.phoneCta)}
      >
        {phoneNumber}
      </PhoneNumberLink>
      <PhoneNumberExt>, TTY 711</PhoneNumberExt>
    </div>
  );
};

export default PhoneNumber;
