import React from 'react';
import styled from 'styled-components/macro';
import { useResponsive } from '@rally/rewards-view/hook';
import { ContentRow } from '@rally/rewards-view/component';
import { breakpoint } from '@rally/rewards-util';
import { page } from '@rally/renew-active/hoc';
import { SectionInfo } from '@rally/renew-active/component';
import { DescriptionImage, PageTitle } from './shared';
import { exerciseYourMindV2, pageTitleV2 } from '../constants/brainText';
import brainHeroV2 from '../assets/brain/brainHeroV2.jpg';
import brainHeroV2Mobile from '../assets/brain/brainHeroV2Mobile.jpg';
import ipadV2 from '../assets/brain/staySharpV2.png';
import ipad2023Update from '../assets/brain/staySharp2023Update.png';
import { config } from '@rally/renew-active/constant';
const Brain = () => {
  const { resp } = useResponsive();
  const renewActive2023CopyUpdates = config.renewActive2023CopyUpdates;
  return (
    <Container>
      <TopContentRow>
        <></>
        <BrainPageTitleV2 text={pageTitleV2} />
      </TopContentRow>
      <ExerciseContentRow>
        <div>
          <SectionInfo {...exerciseYourMindV2} />
        </div>
        <IpadImage
          src={renewActive2023CopyUpdates ? ipad2023Update : ipadV2}
          width={resp('500px', '95%', 'calc(100px + 25%)')}
        />
      </ExerciseContentRow>
    </Container>
  );
};

const BrainPageTitleV2 = styled(PageTitle)`
  width: 100%;

  h1 {
    width: 100%;
  }

  @media ${breakpoint.TABLET} {
    h1 {
      margin-top: -22px;
    }
  }

  @media ${breakpoint.MOBILE} {
    h1 {
      margin-top: 24px;
      text-align: left;
    }
  }
`;

const TopContentRow = styled(ContentRow)`
  background: white url(${brainHeroV2}) no-repeat top left;
  background-size: 100% auto;
  height: 346px;
  z-index: 2;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: 225px;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${brainHeroV2Mobile});
    background-size: cover;
    height: 338px;
    margin: 0;
  }
`;

const ExerciseContentRow = styled(ContentRow)`
  background-color: white;
  padding-top: 20px;
  z-index: 3;

  @media ${breakpoint.TABLET} {
    > div > div > div > div > div > div {
      left: 0;
      position: inherit;
      top: 0;
      transform: initial;

      > div > img {
        margin-top: 60px;
      }
    }
  }

  @media ${breakpoint.MOBILE} {
    margin: 0 0 20px 0;
  }
`;

const Container = styled.div`
  overflow: hidden;
`;

const IpadImage = styled(DescriptionImage)`
  @media ${breakpoint.TABLET_OR_LARGER} {
    img {
      transform: translate(30px, -70px);
    }
  }

  @media ${breakpoint.TABLET} {
    img {
      transform: translate(20px, -50px);
    }
  }

  @media ${breakpoint.MOBILE} {
    width: calc(50px + 45%);

    img {
      width: 100%;
    }
  }
`;
export default page(Brain, 'Brain');
