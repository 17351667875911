import React from 'react';
import styled from 'styled-components/macro';
import { Styled } from '@rally/rewards-view/type';
import { Shape } from '../../Components';
import { useResponsive } from '@rally/rewards-view/hook';

interface DescriptionImageProps {
  src: string;
  width: string;
  alt?: string;
}

const DescriptionImage: React.FC<Styled<DescriptionImageProps>> = ({ className, src, width, alt }) => {
  const { isMobile } = useResponsive();

  return isMobile ? (
    <ImageContainer className={className}>
      <Image alt={alt ? alt : ''} src={src} width={width} />
    </ImageContainer>
  ) : (
    <Shape className={className}>
      <ImageContainer>
        <Image alt={alt ? alt : ''} src={src} width={width} />
      </ImageContainer>
    </Shape>
  );
};

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Image = styled.img<{ width: string }>`
  height: auto;
  width: ${(props) => props.width};
`;

export default DescriptionImage;
