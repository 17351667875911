import { config } from '@rally/renew-active/constant';

const renewActive2023CopyUpdates = config.renewActive2023CopyUpdates;
const title2023Update =
  'Stay focused on brain health with an online program from AARP® Staying Sharp®, including exclusive content for Renew Active® members.';
const contentText2023UpdateIndex0 =
  'Get started by taking the Cognitive Assessment to see how you’re performing today and to learn about strategies to help support brain health throughout your life. Take the Lifestyle Check-Ins and Additional Tests for even more insight.';
const contentTitle2023UpdateIndex3 = 'Staying Sharp® app.';
export const pageTitleV2 = 'Focus on Brain Health';

export const exerciseYourMindV2 = {
  title: renewActive2023CopyUpdates
    ? title2023Update
    : 'Stay focused on brain health with an online program from AARP® Staying Sharp®, including the Cognitive Assessment and Lifestyle Check-Ins and exclusive content for Renew Active members like videos and interactive challenges that can help you build healthy habits.',
  content: [
    {
      title: 'Learn about healthy approaches.',
      text: renewActive2023CopyUpdates
        ? contentText2023UpdateIndex0
        : 'Get started by taking the Cognitive Assessment to get a view into how you’re performing today and enable you to learn about strategies to help support brain health as we age. Plus, take the lifestyle Check-Ins and Additional Tests for more insight.',
    },
    {
      title: 'Feed your mind.',
      text: 'Enjoy articles, activities, and recipes.',
    },
    {
      title: 'Exclusively yours.',
      text: 'Renew Active® members have special access to exclusive content including interactive challenges, and videos.',
    },
    {
      title: renewActive2023CopyUpdates ? contentTitle2023UpdateIndex3 : 'AARP Staying Sharp® App.',
      text: 'Take AARP Staying Sharp interactive challenges with you on the go when you download the mobile app.',
    },
  ],
};

export const transcriptV2 = {
  title: 'Get started with AARP Staying Sharp',
  content: [],
};
