import React from 'react';
import styled from 'styled-components/macro';
import { useMatchMedia } from '@rally/rewards-view/hook';

interface AspectRatioProps {
  ratio: number; // number should be less than 0. 1 means height and width are same size. 2 means height is double than width.
  bp: number; // width at which to stop increasing aspect ratio and just increase width
  offset?: number; // number of pixels by which to increase or decrease the height
}

const AspectRatio: React.FC<AspectRatioProps> = ({ bp, children, offset, ratio }) => {
  if (bp && bp <= 0) {
    throw Error('breakpoint must be positive number');
  }

  const isGreaterThanBreakpoint = useMatchMedia(`(min-width: ${bp}px)`);

  return (
    <AspectRatioContainer bp={bp} offset={offset} ratio={ratio} stopHeightGrowth={isGreaterThanBreakpoint}>
      {children}
    </AspectRatioContainer>
  );
};

const AspectRatioContainer = styled.div<{ stopHeightGrowth: boolean; ratio: number; bp: number; offset?: number }>`
  height: ${(props) => {
    const height = props.stopHeightGrowth ? props.bp * props.ratio + 'px' : props.ratio * 100 + 'vw';
    if (props.offset) {
      return 'calc(' + height + ' + ' + props.offset + 'px)';
    } else {
      return height;
    }
  }};
  overflow: hidden;
  width: 100%;
`;

export default AspectRatio;
