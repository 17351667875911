export const pageTitleV2 = 'Stay active.<br />Stay focused.<br />Stay you.';
export const pageSubtitleV2 =
  'Renew Active® is a  Medicare fitness program for body and mind. Renew Active is available at no additional cost, exclusively from UnitedHealthcare® Medicare Plans.';
export const searchText = '';
export const searchLabel = 'Find a Fitness Location Near You';

export const stayFitSectionV2 = {
  title: 'Stay Active',
  content:
    'Stay active with a free gym membership at a fitness location you select from a national network of over 17,000 locations.',
  cta: 'Search Now',
};

export const staySharpSectionV2 = {
  title: 'Stay Focused',
  content:
    'Stay focused on brain health with an online program from AARP® Staying Sharp®, including the Cognitive Assessment and Lifestyle Check-Ins as well as exclusive content for Renew Active members, such as videos and interactive challenges that can help you build healthy habits.',
  cta: 'Focus on Brain Health',
};

export const stayConnectedSectionV2 = {
  title: 'Stay Connected',
  content:
    'Stay connected socially through local health and wellness classes, clubs and events – such as walking and hiking, arts and crafts, dancing, gardening, bowling, reading, fishing and more.',
  cta: 'Classes, Clubs & Events',
};

export const bottomContentV2 = {
  content: 'Find a UnitedHealthcare Medicare plan, including the only Medicare plans with the AARP name.',
  cta: 'Find a Medicare Plan',
};
