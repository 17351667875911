const sharedSubtitle = 'Enter your ZIP Code to find a list of fitness locations near you.';
const sharedSubtitleUpdate = 'Starting Jan. 1, 2025, Orangetheory Fitness, The Exercise Coach and Xponential Fitness brands including Club Pilates, CycleBar, StretchLab, Rumble, Pure Barre and YogaSix will no longer be in the Renew Active fitness network.';
const sharedTitle = 'Find a Fitness Location';

export const gymSearchHeaderText = {
  subtitle: sharedSubtitle,
  subtitleUpdate: sharedSubtitleUpdate,
  title: `${sharedTitle} Near You`,
};

export const mobileGymSearchHeaderText = {
  subtitle: sharedSubtitle,
  subtitleUpdate: sharedSubtitleUpdate,
  title: sharedTitle,
};
