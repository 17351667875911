import React from 'react';
import styled from 'styled-components/macro';
import { page } from '@rally/renew-active/hoc';
import { SectionInfo } from '@rally/renew-active/component';
import { Title } from '@rally/renew-active/component';
import { BlueRibbonV2, LightBlueRibbonV2 } from '@rally/rewards-view/color';
import { Columns, ContentRow } from '@rally/rewards-view/component';
import { breakpoint } from '@rally/rewards-util';
import { useResponsive } from '@rally/rewards-view/hook';
import { Triangle } from '../Components';
import { PageTitle, TriangleContainer } from './shared';
import { pageTitle2023Update, pageTitleV2, stayActive, stayActive2023Update } from '../constants/classesText';
import classesHeroV2 from '../assets/classes/classesHeroV2.png';
import classesHeroV2Mobile from '../assets/classes/classesHeroV2Mobile.png';
import yogaMatImgV2 from '../assets/classes/yogaMatV2.png';
import balanceFallPreventionV2 from '../assets/classes/balanceFallPreventionV2.svg';
import educationV2 from '../assets/classes/educationV2.svg';
import enduranceV2 from '../assets/classes/enduranceV2.svg';
import yogaV2 from '../assets/classes/yogaV2.svg';
import { config } from '@rally/renew-active/constant';

const Classes = () => {
  const { resp } = useResponsive();
  const balanceFallPreventionImg = <Icon alt="" src={balanceFallPreventionV2} />;
  const educationImg = <Icon alt="" src={educationV2} />;
  const enduranceImg = <Icon alt="" src={enduranceV2} />;
  const yogaImg = <Icon alt="" src={yogaV2} />;
  const renewActive2023CopyUpdates = config.renewActive2023CopyUpdates;

  return (
    <Container>
      <TopContentRow>
        <></>
        <ClassesTitle text={renewActive2023CopyUpdates ? pageTitle2023Update : pageTitleV2} />
      </TopContentRow>
      <StayActiveContentRow>
        <SectionInfo {...(renewActive2023CopyUpdates ? stayActive2023Update : stayActive)} />
        <YogaMatImg alt="" src={yogaMatImgV2} />
      </StayActiveContentRow>
      <TriangleContainer style={{ marginTop: resp('-50px', '-50px', '20px') }} z={4}>
        <Triangle color={LightBlueRibbonV2} dir="left" orientation="bottom" />
      </TriangleContainer>
      <EnrichingContentRow>
        <div>
          <EnrichingTitle>Enriching Your Life While Connecting With Others</EnrichingTitle>
          <Icons>
            <IconsColumns>
              <IconContainer>
                {balanceFallPreventionImg}
                <IconText>Balance &amp; Fall Prevention</IconText>
              </IconContainer>
              <IconContainer>
                {enduranceImg}
                <IconText>Strength &amp; Endurance Training</IconText>
              </IconContainer>
              <IconContainer>
                {yogaImg}
                <IconText>Yoga, Tai Chi, Stretching</IconText>
              </IconContainer>
              <IconContainer>
                {educationImg}
                <IconText>Education</IconText>
              </IconContainer>
            </IconsColumns>
          </Icons>
        </div>
      </EnrichingContentRow>
    </Container>
  );
};

const TopContentRow = styled(ContentRow)`
  background: white url(${classesHeroV2}) no-repeat top left;
  background-size: 100% auto;
  height: 346px;

  @media ${breakpoint.TABLET} {
    background-size: auto 100%;
    height: 225px;
  }

  @media ${breakpoint.MOBILE} {
    background-image: url(${classesHeroV2Mobile});
    background-size: cover;
    height: 377px;
    margin: 0;
  }
`;

const StayActiveContentRow = styled(ContentRow)`
  background-color: white;
  padding-top: 15px;
  z-index: 3;

  @media ${breakpoint.TABLET} {
    > div > div > div > div {
      align-items: flex-start;

      > img {
        margin-right: 20px;
        margin-top: 60px;
      }
    }
  }
`;

const EnrichingContentRow = styled(ContentRow)`
  background-color: ${LightBlueRibbonV2};
  z-index: 2;
`;

const Container = styled.div`
  overflow: hidden;
`;

const ClassesTitle = styled(PageTitle)`
  @media ${breakpoint.TABLET_OR_LARGER} {
    margin-left: 30px;
    width: 110%;

    h1 {
      width: 100%;
    }
  }

  @media ${breakpoint.TABLET} {
    h1 {
      margin-top: -16px;
    }
  }

  @media ${breakpoint.MOBILE} {
    h1 {
      margin-top: 24px;
    }
  }
`;

const EnrichingTitle = styled(Title)`
  color: ${BlueRibbonV2};
  font-weight: 500;
  margin-bottom: 40px;
  padding-top: 20px;
  text-align: center;

  @media ${breakpoint.MOBILE} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const YogaMatImg = styled.img`
  width: 400px;

  @media ${breakpoint.TABLET_OR_LARGER} {
    transform: translateY(-50px);
  }

  @media ${breakpoint.MOBILE} {
    width: 100%;
  }
`;

const Icons = styled.div`
  flex: 1 1 auto;

  @media ${breakpoint.MOBILE} {
    margin-top: 4%;
  }
`;

const IconsColumns = styled(Columns)`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    margin: 0 25px;
    width: 190px;
  }

  @media ${breakpoint.TABLET} {
    flex-direction: row;

    > div {
      width: 125px;
    }
  }

  @media ${breakpoint.MOBILE} {
    align-items: center;
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 190px;

  @media ${breakpoint.TABLET} {
    width: 125px;
  }

  @media ${breakpoint.MOBILE} {
    margin-top: 10px;
  }
`;

const Icon = styled.img`
  border-radius: 50%;
  display: block;
  height: auto;
  width: 98px;

  @media ${breakpoint.MOBILE} {
    margin: 0 auto;
  }
`;

const IconText = styled.p`
  color: ${BlueRibbonV2};
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  width: 100%; /* IE11 fix */

  @media ${breakpoint.MOBILE} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export default page(Classes, 'Classes');
