import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ModalActions, page } from '@rally/renew-active/store';
import { Logo } from '@rally/renew-active/component';
import { Adobe } from '@rally/renew-active/constant';
import { UHCGray, UHCLinkV2 } from '@rally/rewards-view/color';
import { ButtonLink, ExternalLink, Hamburger } from '@rally/rewards-view/component';
import HiddenMobileNav from './HiddenMobileNav';
import { AppState } from '@rally/renew-active/store';
// import PhoneNumberWithCode from './PhoneNumberWithCode';

const MobileHeader: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const toggleNav = useCallback(() => dispatch(page.actions.toggleNav()), [dispatch]);
  const showNav = useSelector((state: AppState) => state.page.showNav);
  return (
    <>
      <Top>
        <HamburgerIcon>
          <HamburgerButton aria-expanded={showNav} aria-label="menu" onClick={toggleNav} tabIndex={0} type="button">
            <Hamburger />
          </HamburgerButton>
          <HiddenMobileNav />
        </HamburgerIcon>
        <LogoContainer>
          <a href="/">
            <Logo />
          </a>
        </LogoContainer>
      </Top>
      <FindPlan>
        <RenewActiveMemberLink
          linkName={Adobe.alreadyMember}
          onClick={(evt) => {
            evt.preventDefault();
            dispatch(ModalActions.renewActiveMemberModal(true));
          }}
          to="#"
        >
          Already have Renew Active?
        </RenewActiveMemberLink>
        <Cta>
          <ButtonLink
            data-testid="planModal"
            linkName={Adobe.findPlan}
            onClick={(evt) => {
              evt.preventDefault();
              dispatch(ModalActions.findAPlanModal(true));
            }}
            theme="darkV2"
            to="#"
          >
            Need a Medicare Plan?
          </ButtonLink>
          {/* <PhoneNumberWithCode inHeader={true} /> */}
        </Cta>
      </FindPlan>
    </>
  );
};

const Item = css`
  align-items: center;
  display: flex;
`;

const Top = styled.div`
  border-bottom: 1px solid ${UHCGray};
  display: flex;
`;

const HamburgerIcon = styled.div`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 50px;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    height: 35px;
  }
`;

const FindPlan = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px 15px 0 15px;
`;

const RenewActiveMemberLink = styled(ExternalLink)`
  color: ${UHCLinkV2};
  font-size: 14px;
  font-weight: bold;
  margin-right: 14px;
  text-decoration: none;
  width: 100px;
`;

const HamburgerButton = styled.button`
  border: 0;
  cursor: pointer;
  display: block;
`;

const Cta = styled.div`
  flex-direction: column;
  justify-content: center;
  ${Item}
`;

export default withRouter(MobileHeader);
