import React from 'react';
import { mapSafe } from '@rally/rewards-util';

interface TriangleProps {
  color: string;
  dir: 'left' | 'right';
  orientation: 'top' | 'bottom';
}

const Triangle: React.FC<TriangleProps> = ({ color, dir, orientation }) => {
  const path = mapSafe(dir, {
    left: mapSafe(orientation, {
      top: 'M0,0 L100,0 L100,20z',
      bottom: 'M0,20 L100,20 L100,0z',
    }),
    right: mapSafe(orientation, {
      top: 'M0,0 L100,0 L0,20z',
      bottom: 'M0,20 L100,20 L0,0z',
    }),
  });

  const viewbox = mapSafe(dir, {
    right: '0 0 100 20',
    left: '0 0 100 20',
  });

  return (
    <svg height="100%" preserveAspectRatio="none" viewBox={viewbox} width="100%">
      <g id="">
        <path d={path} fill={color} />
      </g>
    </svg>
  );
};

export default Triangle;
