export const pageTitleV2 = 'Classes & Events';
export const pageTitle2023Update = 'Classes, Clubs & Events';

export const stayActive = {
  title: 'Stay connected socially through local health and wellness classes and events.',
  title2023Update: 'Stay connected socially through local health and wellness classes, clubs and events.',
  content: [
    {
      title: 'Get a move on.',
      text: 'Enjoy classes in strength and endurance training, yoga, Tai Chi, dancing, hiking, bowling and more.',
    },
    {
      title: 'Be a lifelong learner.',
      text: 'Dig in to sessions on technology, arts and crafts, reading, caregiving and nutrition.',
    },
    {
      title: 'Close, convenient and no additional fee.',
      text: 'Classes and events are held at a wide variety of locations in your community.',
    },
  ],
};
export const stayActive2023Update = {
  title: 'Stay connected socially through local health and wellness classes, clubs and events.',
  content: [
    {
      title: 'Get a move on.',
      text: 'Enjoy classes in strength and endurance training, yoga, Tai Chi, dancing, hiking, bowling and more.',
    },
    {
      title: 'Be a lifelong learner.',
      text: 'Dig in to sessions on technology, arts and crafts, reading, caregiving and nutrition.',
    },
    {
      title: 'Close, convenient and no additional fee.',
      text: 'Classes and events are held at a wide variety of locations in your community.',
    },
  ],
};
